import { AlertTriangle } from "lucide-react";
import { ReactNode } from "react";

export function ErrorMessage({ error }: { error?: ReactNode }) {
  return error ? (
    <div className="mt-2 flex items-center gap-1">
      <AlertTriangle className="text-color-text-error size-4" />
      <span className="text-color-text-error text-xs font-normal">{error}</span>
    </div>
  ) : null;
}
